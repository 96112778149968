import React from "react"
import mobileFilterStyles from "./mobileFilter.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

function mobileFilter({handleClose, children}) {
  return (
    <div className={mobileFilterStyles.container}>
      <div className={mobileFilterStyles.box}>
        <button onClick={handleClose} className={mobileFilterStyles.close}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default mobileFilter