import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"

import HELM from "../../components/helmet.js"
import Layout from "../../components/layout/layout.js"
import catalogPageStyle from "./catalogPage.module.scss"

import DropDown from "../../components/dropDown/dropDown"
import ProductCard from "../../components/productCard/productCard"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import MobileFilter from "../../components/mobileFilter/mobileFilter"

export const catalogQuery = graphql`
  query($collection: String!) {
    shopifyCollection(title: { eq: $collection }) {
      products {
        id
        handle
        title
        tags
        priceRange {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        images {
          localFile {
            relativePath
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        variants {
          shopifyId
          compareAtPrice
          price
          selectedOptions {
            name
            value
          }
          image {
            localFile {
              relativePath
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`

function CatalogPage({ data, pageContext }) {
  const collectionData = data.shopifyCollection
  const collectionTitle = pageContext.collection
  const tagFilter = pageContext.filter

  const [sortFilter, setSortFilter] = useState("Popular")
  const [sortedVariants, setSortedVariants] = useState(new Map())

  useEffect(() => {
    let variantDataVar = {}
    let sortable = []
    let objSorted = {}

    const getAndSetColorMainImage = () => {
      return collectionData.products.forEach(product => {
        let productTitle = product.title
        let productPrice = Number(product.priceRange.maxVariantPrice.amount)
        product.options.forEach(option => {
          // get all variant colors
          if (option.name === "Color") {
            option.values.forEach(color => {
              product.variants.forEach(variant => {
                variant.selectedOptions.forEach(selectedOption => {
                  //log all main images per variant color
                  if (selectedOption.value === color) {
                    if (variantDataVar[productTitle]) {
                      if (!variantDataVar[productTitle].hasOwnProperty(color)) {
                        collectionData.products.forEach(product => {
                          product.images.forEach((image, i) => {
                            if (
                              image.localFile.relativePath ===
                              variant.image.localFile.relativePath
                            ) {
                              variantDataVar = {
                                ...variantDataVar,
                                [productTitle]: {
                                  price: productPrice,
                                  ...variantDataVar[productTitle],
                                  [color]: {
                                    path: variant.image.localFile.relativePath,
                                    index: i,
                                    price: Number(variant.price),
                                  },
                                },
                              }
                            }
                          })
                        })
                      }
                    } else {
                      collectionData.products.forEach(product => {
                        product.images.forEach((image, i) => {
                          if (
                            image.localFile.relativePath ===
                            variant.image.localFile.relativePath
                          ) {
                            variantDataVar = {
                              ...variantDataVar,
                              [productTitle]: {
                                price: productPrice,
                                ...variantDataVar[productTitle],
                                [color]: {
                                  path: variant.image.localFile.relativePath,
                                  index: i,
                                  price: Number(variant.price),
                                },
                              },
                            }
                          }
                        })
                      })
                    }
                  }
                })
              })
            })
          }
        })
      })
    }

    const handleSort = () => {
      //handle sorting
      //create sortable array from variantData
      for (var variant in variantDataVar) {
        sortable.push([
          variant,
          variantDataVar[variant],
          variantDataVar[variant].price,
        ])
      }

      //handle sort filter
      if (sortFilter === "Price: High to Low") {
        sortable.sort(function (a, b) {
          return b[2] - a[2]
        })
      }

      if (sortFilter === "Price: Low to High") {
        sortable.sort(function (a, b) {
          return a[2] - b[2]
        })
      }

      //construct sortedVariants out of sortable array
      sortable.forEach(item => {
        objSorted = { ...objSorted, [item[0]]: item[1] }
      })
    }

    const sort = () => {
      //set to popular if not sorted
      if (objSorted === {}) {
        setSortedVariants(variantDataVar)
      } else {
        setSortedVariants(objSorted)
      }
    }

    getAndSetColorMainImage()
    handleSort()
    sort()
  }, [collectionData.products, sortFilter])

  const [minPrice, setMinPrice] = useState(null)
  const [maxPrice, setMaxPrice] = useState(null)
  useEffect(() => {
    let min = 999999999
    let max = 0

    const getMinPrice = () => {
      collectionData.products.forEach(product => {
        if (Number(product.priceRange.minVariantPrice.amount) < Number(min)) {
          min = Number(product.priceRange.minVariantPrice.amount)
          // setMinPrice(Number(product.priceRange.minVariantPrice.amount))
        }
      })
    }

    const getMaxPrice = () => {
      collectionData.products.forEach(product => {
        if (Number(product.priceRange.maxVariantPrice.amount) > Number(max)) {
          // setMaxPrice(Number(product.priceRange.maxVariantPrice.amount))
          max = Number(product.priceRange.maxVariantPrice.amount)
        }
      })
    }

    getMinPrice()
    getMaxPrice()
    setMinPrice(min)
    setMaxPrice(max)
    setPriceFilter([min, max])
  }, [collectionData.products])

  const [sizeFilter, setSizeFilter] = useState([])
  const [colorFilter, setColorFilter] = useState([])
  const [priceFilter, setPriceFilter] = useState([minPrice, maxPrice])
  const [categoryFilter, setCategoryFilter] = useState(tagFilter)

  const handleFilterReset = () => {
    setPriceFilter([minPrice, maxPrice])
    setColorFilter([])
    setSizeFilter([])
    setCategoryFilter([])
  }

  const [sizes, setSizes] = useState([])

  useEffect(() => {
    let initSize = []
    const getSizes = () => {
      collectionData.products.forEach(product => {
        product.options.forEach(option => {
          if (option.name === "Size") {
            option.values.forEach(size => {
              if (initSize.indexOf(size) < 0) {
                initSize = [...initSize, size]
              }
            })
          }
        })
      })
    }

    getSizes()
    setSizes(initSize)
  }, [collectionData.products])

  const [categories, setCategories] = useState([])

  useEffect(() => {
    let initCategories = []
    let colorFilters = [
      "Red",
      "Green",
      "Blue",
      "Gray",
      "Yellow",
      "Pink",
      "Purple",
      "Orange",
      "Brown",
      "Black",
      "White",
      "Nude",
      "Multi Color",
    ]
    const getCategories = () => {
      collectionData.products.forEach(product => {
        if (product.tags) {
          product.tags
            .filter(tag => !colorFilters.includes(tag))
            .forEach(tag => {
              if(tag === "Blue") {
              }
              if (initCategories.indexOf(tag) < 0) {
                initCategories = [...initCategories, tag]
              }
            })
        }
      })
    }

    getCategories()
    // console.log(initCategories)
    setCategories(initCategories)
  }, [collectionData.products])

  //handle filters function before rendering product variants and to count products
  const isFiltered = useCallback(
    (product, variant) => {
      let sizeFilterExists = false
      let colorFilterExists = false
      let priceFilterExists = false
      let categoryFilterExists = false

      //handle Size Filter
      product.options.forEach(option => {
        if (option.name === "Size") {
          option.values.forEach(value => {
            if (sizeFilter.indexOf(value) > -1 || sizeFilter.length === 0) {
              sizeFilterExists = true
            }
          })
        }
      })

      //handle Color Filter
      product.tags.forEach(tag => {
        if (colorFilter.indexOf(tag) > -1 || colorFilter.length === 0) {
          colorFilterExists = true
        }
      })

      //handle Price Filter
      if (
        priceFilter[0] <= Number(product.priceRange.maxVariantPrice.amount) &&
        priceFilter[1] >= Number(product.priceRange.maxVariantPrice.amount)
      ) {
        priceFilterExists = true
      }

      //handle Category Filter
      product.tags.forEach(tag => {
        if (categoryFilter.indexOf(tag) > -1 || categoryFilter.length === 0) {
          categoryFilterExists = true
        }
      })

      //make sure variant is same as product title if variant is passed
      let isVariantTitle = variant ? variant === product.title : true

      return (
        isVariantTitle &&
        sizeFilterExists &&
        colorFilterExists &&
        priceFilterExists &&
        categoryFilterExists
      )
    },
    [categoryFilter, colorFilter, priceFilter, sizeFilter]
  )

  const variants = () => {
    return Object.keys(sortedVariants).map(variant => {
      return (
        <React.Fragment key={`variant-${variant}`}>
          {collectionData.products
            .filter(product => isFiltered(product, variant))
            .map((product, i) => {
              return (
                <React.Fragment key={`product-${product}-${i}`}>
                  {Object.keys(sortedVariants[variant])
                    .filter(color => color !== "price")
                    .map((color, i) => {
                      // console.log(variantData[variant][color].path, variantData[variant][color].index)
                      // console.log(`${product.handle}-${color}`,variantData[variant][color].index)
                      let frontImageIndex = sortedVariants[variant][color].index
                      let backImageIndex =
                        sortedVariants[variant][color].index + 1

                      if (!(product.images.length > backImageIndex)) {
                        backImageIndex--
                      }

                      let sizeID = product.variants
                        .filter(
                          variant => variant.selectedOptions[1].value === color
                        )
                        .map(variant => [
                          variant.selectedOptions[0].value,
                          variant.shopifyId,
                        ])

                      return (
                        <Link
                          className={catalogPageStyle.cardContainer}
                          to={`/${collectionTitle}/${product.handle}-${color}`
                            .replace(/ /g, "-")
                            .toLowerCase()}
                          key={`catalogPage-${color}-${i}`}
                        >
                          <ProductCard
                            imgFront={
                              product.images[frontImageIndex].localFile
                                .childImageSharp.fluid
                            }
                            imgBack={
                              product.images[backImageIndex].localFile
                                .childImageSharp.fluid
                            }
                            title={`${product.title} - ${color}`}
                            price={product.priceRange.minVariantPrice.amount}
                            compareAt={product.variants[0].compareAtPrice}
                            sizes={sizeID}
                            // sizes={product.options
                            //   .filter(option => option.name === "Size")
                            //   .map(option => option.values)}
                          />
                        </Link>
                      )
                    })}
                </React.Fragment>
              )
            })}
        </React.Fragment>
      )
    })
  }

  // const [variantSizes, setVariantSizes] = useState([])

  // useEffect(() => {
  //   collectionData.variants.forEach(variant => {
  //     variant.selectedOptions.forEach(option => {
  //       if (option.name === "Size" && option.value === selectedSize) {
  //         isVariantSize = true

  //       if (isVariantColor && isVariantSize) {
  //         setVariantId(variant.shopifyId)
  //       }
  //     })
  //   })
  // }, [selectedSize, productColor, productData])

  const [productCount, setProductCount] = useState(0)

  useEffect(() => {
    let count = 0
    const getAndSetProductCount = () => {
      collectionData.products
        .filter(product => isFiltered(product, null))
        .forEach(product => {
          product.options
            .filter(option => option.name === "Color")
            .forEach(option => {
              option.values.forEach(() => {
                count++
              })
            })
        })
    }
    getAndSetProductCount()
    setProductCount(count)
  }, [collectionData.products, isFiltered])

  const handleSizeFilter = sizeClick => {
    let sudoSizeFilter = [...sizeFilter]
    let filterIndex = sizeFilter.indexOf(sizeClick)
    if (filterIndex > -1) {
      sudoSizeFilter.splice(filterIndex, 1)
      setSizeFilter(sudoSizeFilter)
    } else {
      setSizeFilter(sizeFilter => [...sizeFilter, sizeClick])
    }
  }

  const renderSizesFilter = () => {
    return sizes.map((size, i) => {
      return (
        <button
          className={catalogPageStyle.sizeFilter}
          onClick={() => handleSizeFilter(size)}
          key={`size-filter-${size}-${i}`}
        >
          <div className={catalogPageStyle.sizeCheckbox}>
            {sizeFilter.indexOf(size) > -1 ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
          </div>
          <span>{size}</span>
        </button>
      )
    })
  }

  const handleColorFilter = colorClick => {
    let sudoColorFilter = [...colorFilter]
    let filterIndex = colorFilter.indexOf(colorClick)
    if (filterIndex > -1) {
      sudoColorFilter.splice(filterIndex, 1)
      setColorFilter(sudoColorFilter)
    } else {
      setColorFilter(colorFilter => [...colorFilter, colorClick])
    }
  }

  const renderColorFilter = () => {
    let colorFilters = [
      "Red",
      "Green",
      "Blue",
      "Gray",
      "Yellow",
      "Pink",
      "Purple",
      "Orange",
      "Brown",
      "Black",
      "White",
      "Nude",
      "Multi Color",
    ]

    return colorFilters.map((color, i) => {
      return (
        <button
          className={catalogPageStyle.colorContainer}
          onClick={() => handleColorFilter(color)}
          tabIndex={0}
          key={`$colorFilters - ${color}-${i}`}
        >
          <div
            className={catalogPageStyle.outerColorCircle}
            style={{
              border:
                colorFilter.indexOf(color) > -1
                  ? "2px solid black"
                  : "1px solid #b4b4b4",
            }}
          >
            {color === "Nude" ? (
              <div
                className={catalogPageStyle.innerColorCircle}
                style={{
                  backgroundColor: "#E3BC9A",
                }}
              />
            ) : color === "Multi Color" ? (
              <div
                className={catalogPageStyle.innerColorCircle}
                style={{
                  background:
                    "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)",
                }}
              />
            ) : (
              <div
                className={catalogPageStyle.innerColorCircle}
                style={{ backgroundColor: color }}
              />
            )}
          </div>
          <span className={catalogPageStyle.colorName}>{color}</span>
        </button>
      )
    })
  }

  const handleCategoryFilter = categoryClick => {
    let sudoCategoryFilter = [...categoryFilter]
    let filterIndex = categoryFilter.indexOf(categoryClick)
    if (filterIndex > -1) {
      sudoCategoryFilter.splice(filterIndex, 1)
      setCategoryFilter(sudoCategoryFilter)
    } else {
      setCategoryFilter(categoryFilter => [...categoryFilter, categoryClick])
    }
  }

  const renderCategoriesFilter = () => {
    return categories.map((category, i) => {
      return (
        <button
          className={catalogPageStyle.categoryFilter}
          onClick={() => handleCategoryFilter(category)}
          key={`category-filter-${category}-${i}`}
        >
          <div className={catalogPageStyle.categoryCheckbox}>
            {categoryFilter.indexOf(category) > -1 ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faSquare} />
            )}
          </div>
          <span>{category}</span>
        </button>
      )
    })
  }

  const arraysEqual = (a, b) => {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false
    }
    return true
  }

  const [isSizeFiltered, setIsSizeFiltered] = useState(0)

  useEffect(() => {
    arraysEqual([minPrice, maxPrice], priceFilter)
      ? setIsSizeFiltered(0)
      : setIsSizeFiltered(1)
  }, [priceFilter, minPrice, maxPrice])

  const createSliderWithTooltip = Slider.createSliderWithTooltip
  const Range = createSliderWithTooltip(Slider.Range)

  const [hoverSortFilter, setHoverSortFilter] = useState(false)

  const [openMobileFIlter, setOpenMobileFilter] = useState(false)

  return (
    <Layout>
      {collectionTitle === "Women" ? (
        <HELM
          title="Women"
          keywords={[
            `Clothes`,
            `Women`,
            `Filipina`,
            `Teens`,
            `Stylish`,
            `Fashion`,
          ]}
        />
      ) : collectionTitle === "Maternity" ? (
        <HELM
          title="Maternity"
          keywords={[
            `Mom`,
            `Maternity`,
            `Clothing`,
            `Baby`,
            `Comfort`,
            `Stylish`,
          ]}
        />
      ) : null}

      <div className={catalogPageStyle.container}>
        {openMobileFIlter ? (
          <MobileFilter handleClose={() => setOpenMobileFilter(false)}>
            <div className={catalogPageStyle.mobileFilterPopUp}>
              <div className={catalogPageStyle.filterHeader}>
                <div className={catalogPageStyle.filterIcon}>
                  <FontAwesomeIcon icon={faFilter} />
                  <span className={catalogPageStyle.filterTitle}>
                    {` Filters 
                ${
                  tagFilter.length < 1
                    ? sizeFilter.length +
                        colorFilter.length +
                        isSizeFiltered +
                        categoryFilter.length >
                      0
                      ? `(${
                          sizeFilter.length +
                          colorFilter.length +
                          isSizeFiltered +
                          categoryFilter.length
                        })`
                      : ""
                    : ""
                }
              `}
                  </span>
                </div>
                <button
                  className={catalogPageStyle.filterReset}
                  onClick={handleFilterReset}
                >
                  Reset
                </button>
              </div>
              <div className={catalogPageStyle.mobileFilterDropDown}>
                <DropDown title="Size" count={sizeFilter.length}>
                  {renderSizesFilter()}
                </DropDown>
              </div>
              <div className={catalogPageStyle.mobileFilterDropDown}>
                <DropDown title="Color" count={colorFilter.length}>
                  {renderColorFilter()}
                </DropDown>
              </div>
              <div className={catalogPageStyle.mobileFilterDropDown}>
                <DropDown title="Price" count={isSizeFiltered}>
                  <div className={catalogPageStyle.inputSliderContainer}>
                    <div className={catalogPageStyle.inputSlider}>
                      <div className={catalogPageStyle.priceValue}>
                        <span className={catalogPageStyle.minpriceValue}>
                          {`₱${priceFilter[0]}`}
                        </span>
                        <span className={catalogPageStyle.maxpriceValue}>
                          {`₱${priceFilter[1]}`}
                        </span>
                      </div>
                      <Range
                        className={catalogPageStyle.range}
                        min={minPrice}
                        max={maxPrice}
                        onAfterChange={data => setPriceFilter(data)}
                        defaultValue={priceFilter}
                        tipFormatter={value => `₱${value}`}
                      />
                    </div>
                  </div>
                </DropDown>
              </div>
              {tagFilter.length > 0 ? null : (
                <div className={catalogPageStyle.mobileFilterDropDown}>
                  <DropDown title="Category" count={categoryFilter.length}>
                    {renderCategoriesFilter()}
                  </DropDown>
                </div>
              )}
            </div>
          </MobileFilter>
        ) : null}

        <div className={catalogPageStyle.catalogFilterContainer}>
          <div className={catalogPageStyle.filters}>
            <div className={catalogPageStyle.filterHeader}>
              <div className={catalogPageStyle.filterIcon}>
                <FontAwesomeIcon icon={faFilter} />
                <span className={catalogPageStyle.filterTitle}>
                  {` Filters 
                ${
                  tagFilter.length < 1
                    ? sizeFilter.length +
                        colorFilter.length +
                        isSizeFiltered +
                        categoryFilter.length >
                      0
                      ? `(${
                          sizeFilter.length +
                          colorFilter.length +
                          isSizeFiltered +
                          categoryFilter.length
                        })`
                      : ""
                    : ""
                }
              `}
                </span>
              </div>
              <button
                className={catalogPageStyle.filterReset}
                onClick={handleFilterReset}
              >
                Reset
              </button>
            </div>
            <DropDown title="Size" count={sizeFilter.length}>
              {renderSizesFilter()}
            </DropDown>
            <DropDown title="Color" count={colorFilter.length}>
              {renderColorFilter()}
            </DropDown>
            <DropDown title="Price" count={isSizeFiltered}>
              <div className={catalogPageStyle.inputSliderContainer}>
                <div className={catalogPageStyle.inputSlider}>
                  <div className={catalogPageStyle.priceValue}>
                    <span className={catalogPageStyle.minpriceValue}>
                      {`₱${priceFilter[0]}`}
                    </span>
                    <span className={catalogPageStyle.maxpriceValue}>
                      {`₱${priceFilter[1]}`}
                    </span>
                  </div>
                  <Range
                    className={catalogPageStyle.range}
                    min={minPrice}
                    max={maxPrice}
                    onAfterChange={data => setPriceFilter(data)}
                    defaultValue={priceFilter}
                    tipFormatter={value => `₱${value}`}
                  />
                </div>
              </div>
            </DropDown>
            {tagFilter.length > 0 ? null : (
              <DropDown title="Category" count={categoryFilter.length}>
                {renderCategoriesFilter()}
              </DropDown>
            )}
          </div>

          <div className={catalogPageStyle.catalogSection}>
            <div className={catalogPageStyle.mobileTitleCountContainer}>
              <h1 className={catalogPageStyle.title}>
                {tagFilter.length > 0
                  ? `${tagFilter[0]} | ${collectionTitle}`
                  : collectionTitle}
              </h1>
              <span
                className={catalogPageStyle.count}
              >{`(${productCount})`}</span>
            </div>
            <div className={catalogPageStyle.sortFilter}>
              <div className={catalogPageStyle.titleCountContainer}>
                <h1 className={catalogPageStyle.title}>
                  {tagFilter.length > 0
                    ? `${tagFilter[0]} | ${collectionTitle}`
                    : collectionTitle}
                </h1>
                <span
                  className={catalogPageStyle.count}
                >{`(${productCount})`}</span>
              </div>
              <button
                className={catalogPageStyle.mobileFilterContainer}
                onClick={() => setOpenMobileFilter(true)}
              >
                <div className={catalogPageStyle.filterIcon}>
                  <FontAwesomeIcon icon={faFilter} />
                  <span className={catalogPageStyle.filterTitle}>
                    {` Filters 
                ${
                  tagFilter.length < 1
                    ? sizeFilter.length +
                        colorFilter.length +
                        isSizeFiltered +
                        categoryFilter.length >
                      0
                      ? `(${
                          sizeFilter.length +
                          colorFilter.length +
                          isSizeFiltered +
                          categoryFilter.length
                        })`
                      : ""
                    : ""
                }
              `}
                  </span>
                </div>
              </button>
              <div
                className={catalogPageStyle.sortContainer}
                onMouseEnter={() => setHoverSortFilter(true)}
                onMouseLeave={() => setHoverSortFilter(false)}
                onClick={() => setHoverSortFilter(!hoverSortFilter)}
                onKeyDown={() => setHoverSortFilter(!hoverSortFilter)}
                role="button"
                tabIndex={0}
              >
                <div className={catalogPageStyle.sortTextIcon}>
                  <FontAwesomeIcon icon={faSortAmountDownAlt} />
                  <span
                    className={catalogPageStyle.sortText}
                  >{`Sort: ${sortFilter}`}</span>
                </div>
                <div
                  style={{
                    opacity: hoverSortFilter ? 1 : 0,
                    zIndex: hoverSortFilter ? 10 : -1,
                  }}
                  className={catalogPageStyle.sortDropdown}
                >
                  <button
                    className={catalogPageStyle.filterDropDownDivTop}
                    onClick={() => {
                      setSortFilter("Popular")
                      setHoverSortFilter(false)
                    }}
                  >
                    <span className={catalogPageStyle.filterDropDownSpan}>
                      Popular
                    </span>
                  </button>
                  <button
                    className={catalogPageStyle.filterDropDownDiv}
                    onClick={() => {
                      setSortFilter("Price: High to Low")
                      setHoverSortFilter(false)
                    }}
                  >
                    <span className={catalogPageStyle.filterDropDownSpan}>
                      Price: High to Low
                    </span>
                  </button>
                  <button
                    className={catalogPageStyle.filterDropDownDivBottom}
                    onClick={() => {
                      setSortFilter("Price: Low to High")
                      setHoverSortFilter(false)
                    }}
                  >
                    <span className={catalogPageStyle.filterDropDownSpan}>
                      Price: Low to High
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className={catalogPageStyle.catalog}>{variants()}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CatalogPage
